import { CircularProgress, LinearProgress } from '@mui/material';
import { Branding, useLocalStorageState } from '@toolpad/core';
import { AppProvider } from '@toolpad/core/react-router-dom';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import SEICLogoDark from './assets/SEICLogoDark.svg';
import SEICLogoLight from './assets/SEICLogoLight.svg';
import { useUser } from './hooks/useUser';
import './index.css';
import SignIn from './pages/SignIn';
import { darkTheme, lightTheme } from './themes';

function App() {
  const { isAuthenticated, isError, isLoading, nav, userConfig } = useUser();
  const [theme] = useLocalStorageState('toolpad-mode', 'dark');

  const BRANDING: Branding = useMemo(
    () => ({
      title: '',
      logo: (
        <img src={theme == 'dark' ? SEICLogoDark : SEICLogoLight} height="100%" alt="SEIC Logo" />
      ),
    }),
    [theme]
  );

  if (!isAuthenticated)
    return (
      <AppProvider theme={{ dark: darkTheme, light: darkTheme }}>
        <SignIn />
      </AppProvider>
    );
  if (isAuthenticated && isLoading) return <CircularProgress />;

  return (
    <>
      <AppProvider
        navigation={nav}
        branding={BRANDING}
        theme={{ dark: darkTheme, light: lightTheme }}
      >
        {!!isLoading && <LinearProgress />}
        <Outlet />
      </AppProvider>
    </>
  );
}

export default App;
