import { BuilderType } from "./projectsApiHelpers";

export const miscApi = (builder: BuilderType) => ({
  sendPasswordResetEmail: builder.mutation<any, string>({
    query: (email) => ({
      url: `service/send-password-reset`,
      method: "PUT",
      body: { email },
    }),
  }),

  clearAMUnexpectedAssetList: builder.mutation<any, void>({
    query: (ids) => ({
      url: "/service/am-unexpected-assets/clear-list",
      method: "DELETE",
      body: { ids },
    }),
  }),

  ignoreAMAssetList: builder.mutation<any, number>({
    query: (ids) => ({
      url: "/service/am-assets/ignore-list",
      method: "POST",
      body: { ids },
    }),
  }),
});
