import { useCallback, useMemo, useState } from 'react';

import { CloudUpload } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid2,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import Papa from 'papaparse';
import PageContainer from '../components/PageContainer';
import { seicDark } from '../themes';

const ProjectStatus = () => {
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [issues, setIssues] = useState<any[]>([]);

  const handleFileUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          setIssues(results.data);
          setSelectedLabels([]);
          // You can set the parsed data to state here if needed
        },
      });
    }
  }, []);

  const handleToggleLabel = (label: string) => {
    if (selectedLabels.includes(label)) {
      setSelectedLabels(selectedLabels.filter((selectedLabel) => selectedLabel !== label));
    } else {
      setSelectedLabels([...selectedLabels, label]);
    }
  };

  const issuesByLabel = useMemo(() => {
    const labels = {};
    const categoryMap = {
      'To Do': 'toDo',
      'In Progress': 'inProgress',
      Done: 'done',
    };

    structuredClone(issues).forEach((issue) => {
      const labelKeys = Object.keys(issue).filter((key) => key.includes('Labels'));
      issue.Labels = labelKeys.map((key) => issue[key]).filter((label) => label);
      console.log(issue.Labels);
      issue.Labels?.forEach((label) => {
        if (label) {
          labels[label] = labels[label] ?? {
            done: 0,
            inProgress: 0,
            toDo: 0,
            total: 0,
            issues: [],
          };
          labels[label].issues.push(issue);
          const category = categoryMap[issue['Status Category']];
          labels[label][category] += 1;
          labels[label].total += 1;
        }
      });
    });
    setSelectedLabels(Object.keys(labels));
    return labels;
  }, [issues]);

  return (
    <PageContainer
      actions={
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUpload />}
        >
          Upload CSV File
          <input
            style={{
              clip: 'rect(0 0 0 0)',
              clipPath: 'inset(50%)',
              height: 1,
              overflow: 'hidden',
              position: 'absolute',
              bottom: 0,
              left: 0,
              whiteSpace: 'nowrap',
              width: 1,
            }}
            accept=".csv"
            type="file"
            onChange={handleFileUpload}
          />
        </Button>
      }
    >
      <Grid2 container spacing={2} textAlign={'left'}>
        <Grid2 size={3}>
          <Paper>
            <Box p={3}>
              <Typography variant="h3">Categories</Typography>
              <FormGroup>
                {Object.keys(issuesByLabel).map((label) => (
                  <FormControlLabel
                    key={label}
                    control={
                      <Checkbox
                        checked={selectedLabels.includes(label)}
                        onChange={() => handleToggleLabel(label)}
                      />
                    }
                    label={label}
                  />
                ))}
              </FormGroup>
            </Box>
          </Paper>
        </Grid2>
        <Grid2 size={9}>
          <Paper>
            <Box p={3}>
              <Typography variant="h3" gutterBottom>
                Progress
              </Typography>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Box
                    width={16}
                    height={16}
                    bgcolor={seicDark.primary}
                    mr={1}
                    borderRadius="2px"
                  />
                  <Typography variant="body2" mr={2}>
                    Done
                  </Typography>
                  <Box
                    width={16}
                    height={16}
                    bgcolor={seicDark.secondary}
                    mr={1}
                    borderRadius="2px"
                  />
                  <Typography variant="body2" mr={2}>
                    In Progress
                  </Typography>
                  <Box
                    width={16}
                    height={16}
                    bgcolor={seicDark.disabled}
                    mr={1}
                    borderRadius="2px"
                  />
                  <Typography variant="body2">To Do</Typography>
                </Box>
                <Box mb={2} textAlign={'right'}>
                  <Typography variant="body2">
                    To Do:{' '}
                    {selectedLabels.reduce((acc, label) => acc + issuesByLabel[label].toDo, 0)}
                  </Typography>
                  <Typography variant="body2">
                    In Progress:{' '}
                    {selectedLabels.reduce(
                      (acc, label) => acc + issuesByLabel[label].inProgress,
                      0
                    )}
                  </Typography>
                  <Typography variant="body2">
                    Done:{' '}
                    {selectedLabels.reduce((acc, label) => acc + issuesByLabel[label].done, 0)}
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    Total Issues:{' '}
                    {selectedLabels.reduce((acc, label) => acc + issuesByLabel[label].total, 0)}
                  </Typography>
                </Box>
              </Box>
              {Object.entries(issuesByLabel)
                .filter(([key]) => selectedLabels.includes(key))
                .map(([key, value]) => {
                  const { done, inProgress, toDo, total } = value;
                  const donePercent = (done / total) * 100;
                  const inProgressPercent = (inProgress / total) * 100;
                  const toDoPercent = (toDo / total) * 100;
                  return (
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <Typography flex={1} variant="caption">
                          {key}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {total} Issues
                        </Typography>
                      </Box>
                      <Box
                        height={8}
                        width="100%"
                        sx={{
                          height: '20px',
                          width: '100%',
                          borderRadius: '2px',
                          overflow: 'hidden',
                          background: '#ccc',
                          display: 'flex',
                          mb: 1,
                        }}
                      >
                        <Tooltip title={`${done} Issues`}>
                          <Box
                            height={'100%'}
                            bgcolor={seicDark.primary}
                            width={`${donePercent}%`}
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <Typography color={seicDark.textHighEmphasis} variant="caption">
                              {donePercent.toFixed(0)}%
                            </Typography>
                          </Box>
                        </Tooltip>
                        {!!inProgressPercent && (
                          <Tooltip title={`${inProgress} Issues`}>
                            <Box
                              height={'100%'}
                              bgcolor={seicDark.secondary}
                              width={`${inProgressPercent}%`}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography color={seicDark.textHighEmphasis} variant="caption">
                                {inProgressPercent.toFixed(0)}%
                              </Typography>
                            </Box>
                          </Tooltip>
                        )}
                        {!!toDoPercent && (
                          <Tooltip title={`${toDo} Issues`}>
                            <Box
                              height={'100%'}
                              bgcolor={seicDark.disabled}
                              width={`${toDoPercent}%`}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography color={seicDark.textHighEmphasis} variant="caption">
                                {toDoPercent.toFixed(0)}%
                              </Typography>
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                    </>
                  );
                })}
            </Box>
          </Paper>
          {/* Right side content goes here */}
        </Grid2>
      </Grid2>
    </PageContainer>
  );
};

export default ProjectStatus;
