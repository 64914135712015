import { Add, RemoveCircle } from '@mui/icons-material';
import { Box, Button, Grid2, IconButton, MenuItem, Typography } from '@mui/material';
import { Form } from '@rjsf/mui';
import { FieldProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useMemo, useState } from 'react';
import { seicDark } from '../../themes';
import JSONSchemaMainTabs from './JSONSchemaMainTabs';

const JSONSchemaArrayTabs = ({ schema, formData, onChange }: FieldProps) => {
  const { fields } = useMemo(() => {
    const _fields = {};
    Object.entries(schema).forEach(([_, value]) => {
      if (value.type === 'object') {
        console.log(value.$id);
        if (value.$id?.includes('mainTabs')) {
          _fields[value.$id] = JSONSchemaMainTabs;
        }
      }
    });

    return { fields: _fields };
  }, [schema]);

  const [selectedTab, setSelectedTab] = useState(formData.length - 1);

  const handleAdd = () => {
    onChange([...formData, {}]);
    setSelectedTab(formData.length);
  };

  const handleDeleteClick = (index: number) => {
    const _data = structuredClone(formData);
    _data.splice(index, 1);
    onChange(_data);
    setSelectedTab(_data.length - 1);
  };
  const handleFormChange = (data: any) => {
    const _data = structuredClone(formData);
    _data[selectedTab] = data.formData;
    onChange(_data);
  };
  const handleSubmit = (data: any) => {
    console.log(data);
  };

  const itemLabel = schema.title ?? 'Item';

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 4, xl: 3 }} borderRight={'solid 1px'} borderColor={'divider'}>
          <Box>
            {formData.map((_, index) => {
              const selected = index === selectedTab;
              return (
                <MenuItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: selected ? 'primary.main' : null,
                    borderRight: selected ? 'solid 3px' : null,
                    fontWeight: selected ? 'bold' : null,
                  }}
                  onClick={() => setSelectedTab(index)}
                >
                  {itemLabel}: {index + 1}{' '}
                  <IconButton onClick={() => handleDeleteClick(index)} size="small">
                    <RemoveCircle sx={{ color: seicDark.critical }} />
                  </IconButton>
                </MenuItem>
              );
            })}
            <Button variant="contained" onClick={handleAdd} sx={{ mt: 2 }}>
              <Add /> Add {itemLabel}
            </Button>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 8, xl: 9 }}>
          <Box padding={2}>
            {formData[selectedTab] ? (
              <Form
                schema={schema.items}
                formData={formData[selectedTab] ?? {}}
                experimental_defaultFormStateBehavior={{
                  emptyObjectFields: 'populateRequiredDefaults',
                }}
                fields={fields}
                validator={validator}
                onChange={handleFormChange}
                onSubmit={handleSubmit}
              >
                <div></div>
              </Form>
            ) : (
              <Typography>No data</Typography>
            )}
          </Box>
        </Grid2>
      </Grid2>
    </>
  );
};

export default JSONSchemaArrayTabs;
