//import validator from '@rjsf/validator-ajv6';
import { Drawer, Typography } from '@mui/material';
import { useState } from 'react';
import SimulatorDetail from './SimulatorDetail';
import SimulatorTable from './SimulatorTable';
import { PageContainer } from '@toolpad/core';

const SimulatorPage = () => {
  const [simulator, setSimulator] = useState(null);

  const handleDrawerClose = () => {
    setSimulator(null);
  };

  const handleRowClick = (row: Asset) => {
    setSimulator(row);
  };

  return (
    <PageContainer title="Simulator" breadcrumbs={[{ title: 'Simulator', path: '/simulator' }]}>
      <SimulatorTable onRowClick={handleRowClick} />
      <Drawer
        anchor="right"
        open={!!simulator}
        onClose={handleDrawerClose}
        sx={{ '& .MuiDrawer-paper': { width: { xs: '100%', sm: '80%' } } }}
      >
        {simulator && <SimulatorDetail simulator={simulator} />}
      </Drawer>
    </PageContainer>
  );
};

export default SimulatorPage;
