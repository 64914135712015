import React from 'react';
import { Box, Button, Container, Grid, Paper, Typography, useColorScheme } from '@mui/material';

const data = [
  {
    name: 'Jan',
    customers: 4000,
    dealers: 2400,
    assets: 2400,
    team: 2000,
    alarms: 1000,
  },
  {
    name: 'Feb',
    customers: 3000,
    dealers: 1398,
    assets: 2210,
    team: 1800,
    alarms: 900,
  },
  {
    name: 'Mar',
    customers: 2000,
    dealers: 9800,
    assets: 2290,
    team: 1500,
    alarms: 800,
  },
  {
    name: 'Apr',
    customers: 2780,
    dealers: 3908,
    assets: 2000,
    team: 1700,
    alarms: 700,
  },
  {
    name: 'May',
    customers: 1890,
    dealers: 4800,
    assets: 2181,
    team: 1600,
    alarms: 600,
  },
  {
    name: 'Jun',
    customers: 2390,
    dealers: 3800,
    assets: 2500,
    team: 1400,
    alarms: 500,
  },
  {
    name: 'Jul',
    customers: 3490,
    dealers: 4300,
    assets: 2100,
    team: 1300,
    alarms: 400,
  },
];

const Dashboard: React.FC = () => {
  const { mode, setMode } = useColorScheme();
  return (
    <Box maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Button onClick={() => setMode(mode === 'dark' ? 'light' : 'dark')}>Toggle Theme</Button>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Customers</Typography>
            <Typography variant="h4">1,200</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Dealers</Typography>
            <Typography variant="h4">300</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Assets</Typography>
            <Typography variant="h4">5,000</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Team</Typography>
            <Typography variant="h4">50</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Alarms</Typography>
            <Typography variant="h4">20</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Monthly Data</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
