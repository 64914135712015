import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { Resource } from "./resourcesApi";
export type NewTeam = {};

export const newTeamsApi = (builder: BuilderType) => ({
  getNewTeams: builder.query<NewTeam, void>({
    query: () => `service/new-teams`,
    providesTags: [
      projectsCacheTags.NEW_TEAMS,
      projectsCacheTags.NEW_TEAM_RESOURCES,
      projectsCacheTags.GOALS,
      projectsCacheTags.CLIENT_OWNERS,
    ],
  }),

  createNewTeam: builder.mutation<NewTeam, any>({
    query: (team) => ({
      url: `service/new-teams`,
      method: "POST",
      body: team,
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),

  updateNewTeam: builder.mutation<NewTeam, any>({
    query: (team) => ({
      url: `service/new-teams/${team.id}`,
      method: "PUT",
      body: team,
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),

  deleteNewTeam: builder.mutation<NewTeam, number>({
    query: (id) => ({
      url: `service/new-teams/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),

  createNewTeamResource: builder.mutation<Resource, any>({
    query: (teamResource) => ({
      url: `service/new-teams/resources`,
      method: "POST",
      body: teamResource,
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),

  deleteNewTeamResource: builder.mutation<Resource, number>({
    query: (id) => ({
      url: `service/new-teams/resources/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.NEW_TEAMS],
  }),
});
