import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type AuthenticateParams = {
  email: string;
  password: string;
  rememberMe?: boolean;
};

type AuthenticateResponse = {
  auth_token: string;
  email: string;
};

export const authenticateApi = createApi({
  reducerPath: 'authenticateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_PORTAL_SERVICE}/`,
  }),
  endpoints: (builder) => ({
    authenticate: builder.mutation<AuthenticateResponse, AuthenticateParams>({
      query: (auth) => ({
        url: `service/authenticate`,
        method: 'POST',
        body: auth,
      }),
    }),
  }),
});
const { useAuthenticateMutation } = authenticateApi;

export { useAuthenticateMutation };
