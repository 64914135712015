import IconProps from './IconProps';

const PowerLogger = (props: IconProps) => (
  <svg
    width={props.width ?? 'auto'}
    height={props.height ?? '1.2em'}
    style={{ margin: '0 auto', ...(props.style ?? {}) }}
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M19.056.686c-.024-.388-.4-.686-.855-.686H2.909c-.453 0-.83.298-.856.686L-.001 34.144c-.024.414.367.76.857.76h5.051v2.723c0 .099.04.194.11.264.071.07.167.109.267.109h.576a.38.38 0 00.348-.23.369.369 0 00.03-.143v-2.722H7.9v2.722c0 .099.04.194.11.264s.167.109.267.109h.573c.1 0 .196-.04.266-.11.071-.07.11-.164.11-.263v-2.722h.664v2.722c0 .099.04.194.11.264.071.07.167.109.267.109h.573c.1 0 .196-.04.267-.11.07-.07.11-.164.11-.263v-2.722h.664v2.722c0 .099.04.194.11.264s.167.109.267.109h.573c.1 0 .195-.04.266-.11.07-.07.11-.164.11-.263v-2.722h.664v2.722c0 .099.04.194.11.264.071.07.167.109.267.109h.573c.1 0 .196-.04.267-.11.07-.07.11-.164.11-.263v-2.722h5.052c.49 0 .88-.347.855-.76L19.056.685z"
        fill={(props.fill || props.style?.fill) ?? 'currentColor'}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h21.111v38H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PowerLogger;
