import { useTheme } from '@mui/material';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import { Outlet } from 'react-router-dom';
import { seicLight } from '../themes';
import Account from './Account';
import { MainToolbarActions } from './MainToolbarActions';
import ServiceWorkerPrompt from './ServiceWorkerPrompt';

const colors = {
  dark: {
    selectedBackground: '#00b2a926',
    selectedColor: '#fff',
  },
  light: {
    selectedBackground: seicLight.primary200,
    selectedColor: seicLight.textHighEmphasis,
  },
};

export default function Layout() {
  const theme = useTheme();
  const { selectedBackground, selectedColor } = colors[theme.palette.mode];
  return (
    <DashboardLayout
      sidebarExpandedWidth={225}
      sx={{
        '> header': { background: 'var(--mui-overrides-appVar-backgroundColor)' },
        '> .MuiDrawer-docked': {
          '> .MuiPaper-root': {
            background: 'var(--mui-overrides-appVar-backgroundColor)',
            'nav ul li > a': {
              transform: 'translateX(-8px)',
              paddingLeft: '20px',
              borderRadius: '0',
              height: '40px',
              '&.Mui-selected': {
                background: selectedBackground,
                'svg, span': {
                  color: selectedColor,
                },
                '&:hover': {
                  background: selectedBackground,
                  color: selectedColor,
                },
              },
              '&::after': {
                content: "''",
                width: '16px',
                height: '100%',
                position: 'absolute',
                background: 'inherit',
                right: '-16px',
                borderRadius: '0 30px 30px 0',
              },
            },
          },
        },
      }}
      slots={{ toolbarAccount: Account, toolbarActions: MainToolbarActions }}
    >
      <Outlet />
      <ServiceWorkerPrompt />
    </DashboardLayout>
  );
}
