import SettingsIcon from '@mui/icons-material/Settings';
import { Divider, ListItemIcon, MenuItem, MenuList, Stack } from '@mui/material';
import { AccountPopoverFooter, AccountPreview, SignOutButton } from '@toolpad/core';
import { useNavigate } from 'react-router-dom';

export default function AccountMenu() {
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate('/profile');
  };
  return (
    <Stack direction="column">
      <AccountPreview variant="expanded" />
      <Divider />
      <MenuList disablePadding>
        <MenuItem
          component="button"
          onClick={handleSettingsClick}
          sx={{
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          Settings
        </MenuItem>
      </MenuList>
      <Divider />
      <AccountPopoverFooter>
        <SignOutButton />
      </AccountPopoverFooter>
    </Stack>
  );
}
