import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Grid2,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useColorScheme,
} from '@mui/material';
import { Form } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import {
  useLastLeadEventBySerialQuery,
  useLastVanadiumEventBySerialQuery,
  useLeadEventMutation,
  useVanadiumEventMutation,
} from '../../services/connectApi';
import { Asset } from '../../services/portal/assetsApi';
import JSONSchemaMainTabs from './JSONSchemaMainTabs';
import leadBessSchema from './lead-bess-schema.json';
import leadBessDemoData from './leadacid-sample.json';
import vanadiumSchema from './vanadium-bess-event-schema.json';
import vanadiumDemoData from './vanadium-sample.json';

import { Download, Error } from '@mui/icons-material';
import { RJSFValidationError } from '@rjsf/utils';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-twilight';

const schemaObj = {
  vanadium_bess: {
    schema: vanadiumSchema,
    demoData: vanadiumDemoData,
  },
  leadacid_bess: {
    schema: leadBessSchema,
    demoData: leadBessDemoData,
  },
};

type VanadiumEvent = typeof vanadiumDemoData;
type LeadEvent = typeof leadBessDemoData;

type Props = {
  simulator: Asset;
};
const SimulatorDetail = ({ simulator }: Props) => {
  const [data, setData] = useState<VanadiumEvent | LeadEvent>({});
  const [rawJsonErrors, setRawJsonErrors] = useState<RJSFValidationError[]>([]);
  const { mode: colorMode } = useColorScheme();
  const { schema, demoData } = schemaObj[simulator.asset_type] ?? {};
  const [mode, setMode] = useState<'form' | 'json'>('form');
  const aceEditorRef = useRef<AceEditor>(null);
  const type = simulator.asset_type;
  const serial = simulator.name;

  const [sendVanadiumEvent, { isLoading: vadiumEventLoading }] = useVanadiumEventMutation();
  const [sendLeadEvent, { isLoading: leadEventLoading }] = useLeadEventMutation();
  const { data: lastLeadEvent, refetch: lastLeadRefetch } = useLastLeadEventBySerialQuery(serial, {
    skip: type !== 'leadacid_bess' || !serial,
  });
  const { data: lastVanadiumEvent, refetch: lastVanadiumRefetch } =
    useLastVanadiumEventBySerialQuery(serial, { skip: type !== 'vanadium_bess' || !serial });

  const lastEvent = type === 'vanadium_bess' ? lastVanadiumEvent : lastLeadEvent;

  const fetchLastEvent = useCallback(() => {
    if (type === 'vanadium') {
      lastVanadiumRefetch();
    } else {
      lastLeadRefetch();
    }
  }, [lastLeadRefetch, lastVanadiumRefetch, type]);

  const handleUseDemoDataClick = () => {
    const _data = structuredClone(demoData);
    setData(_data);
  };

  const sendEvent = async () => {
    const body = { ...data };
    body.data.map((d) => {
      d.schema = 0.2;
      d.datetimestamp = new Date().toISOString();
      d.battery_id = serial;
      d.sample_id = +`${new Date().getTime()}999`;
    });
    body.serial = serial;
    try {
      const request = type === 'vanadium_bess' ? sendVanadiumEvent(body) : sendLeadEvent(body);
      await request.unwrap();

      enqueueSnackbar(`Event sent successfully \n sample_id:${body.data[0].sample_id}`, {
        variant: 'success',
      });
      setTimeout(() => {
        fetchLastEvent();
      }, 2000);
    } catch (e) {
      enqueueSnackbar(e.data?.message ?? e?.message ?? 'unexpected error', { variant: 'error' });
    }
  };

  const handleToggleMode = (_, value: typeof mode) => {
    if (value !== null) setMode(value);
  };

  const handleSubmit = async () => {
    // form validation is made by the schema component
    sendEvent();
  };

  const handleRawJsonChange = (value: string, e) => {
    // we only update the object if the json is valid
    const insertText = e.lines.join('').trim();
    if (insertText) {
      try {
        const parsed = JSON.parse(value);
        const { errors } = validator.validateFormData(parsed, schema);
        setData(parsed);
        setRawJsonErrors(errors);
      } catch (e) {}
    }
  };

  const handleRawJsonSubmit = async () => {
    // json validation is needed before sending the event
    const { errors } = validator.validateFormData(data, schema);
    setRawJsonErrors(errors);
    if (errors.length) {
      enqueueSnackbar('Errors in JSON', { variant: 'error' });
      return;
    } else sendEvent();
  };

  const handleDownloadJSON = () => {
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${serial}-event.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleFormChange = ({ formData }: { formData: VanadiumEvent | LeadEvent }) => {
    setData(formData);
  };

  const fields = useMemo(() => ({ '/mainTabs/battery': JSONSchemaMainTabs }), []);

  return (
    <Box padding={{ sx: 0, md: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          padding: '10px,0',
          zIndex: 100,
          alignItems: 'center',
        }}
      >
        <header>
          <Typography variant="h5">{simulator.alias ?? simulator.name}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {simulator.name}
          </Typography>
        </header>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="outlined" onClick={handleUseDemoDataClick}>
            Use demo data
          </Button>
          <ToggleButtonGroup size="small" value={mode} exclusive onChange={handleToggleMode}>
            <ToggleButton color="primary" value="form">
              Form
            </ToggleButton>
            <ToggleButton color="primary" value="json">
              JSON
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 4, xl: 3 }}>
          <Paper sx={{ padding: 2, position: 'sticky', top: 60 }}>
            <Typography variant="h6">Last Event</Typography>
            {Object.entries(lastEvent ?? {}).map(([key, value]) => (
              <Box display="flex" key={key} gap={1} justifyContent={'space-between'}>
                {typeof value !== 'object' ? (
                  <>
                    <Typography variant="caption">{key}:</Typography>
                    <Typography
                      variant="body2"
                      sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    >
                      {value ?? ''}
                    </Typography>
                  </>
                ) : null}
              </Box>
            ))}
          </Paper>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 8, xl: 9 }}>
          <Box
            sx={{
              '#root_data__title, #root_data_0__title': {
                display: 'none',
              },
              '.MuiGrid-root:first-of-type': {
                marginTop: '0 !important',
                '> .MuiGrid-root': {
                  padding: 0,
                },
              },
              '.MuiGrid-container:first': {},
              h5: {
                textAlign: 'left',
              },
            }}
          >
            {mode === 'form' ? (
              <Form
                schema={schema}
                formData={data}
                experimental_defaultFormStateBehavior={{
                  emptyObjectFields: 'populateRequiredDefaults',
                }}
                fields={fields}
                uiSchema={{
                  serial: { 'ui:widget': 'hidden' },
                  data: {
                    items: {
                      schema: { 'ui:disabled': true },
                      battery_id: { 'ui:disabled': true },
                      datetimestamp: { 'ui:disabled': true },
                    },
                  },
                }}
                validator={validator}
                onChange={handleFormChange}
                onSubmit={handleSubmit}
              >
                <Paper
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    padding: 2,
                    justifyContent: 'flex-end',
                    zIndex: 100,
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <LoadingButton size="small" onClick={handleDownloadJSON}>
                    <Download /> Download json
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    loading={leadEventLoading || vadiumEventLoading}
                  >
                    Submit
                  </LoadingButton>
                </Paper>
              </Form>
            ) : (
              <>
                {!!rawJsonErrors.length && (
                  <Paper sx={{ padding: 2 }}>
                    <Typography variant="h6">Errors</Typography>
                    {rawJsonErrors.slice(-3).map((e) => (
                      <Box>
                        <Error color="error" />

                        <Typography variant="caption">
                          {e.property} {e.message}
                        </Typography>
                      </Box>
                    ))}
                  </Paper>
                )}
                <AceEditor
                  mode="json"
                  theme={colorMode === 'dark' ? 'twilight' : 'tomorrow'}
                  onChange={handleRawJsonChange}
                  name="rawJsonEvent"
                  ref={aceEditorRef}
                  style={{ width: '100%', height: 'calc(100vh - 80px)' }}
                  value={JSON.stringify(data, null, 2)}
                  editorProps={{ $blockScrolling: true }}
                />
                <Paper
                  sx={{
                    zIndex: 100,
                    position: 'sticky',
                    bottom: 0,
                    padding: 2,
                    justifyContent: 'flex-end',
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <LoadingButton size="small" onClick={handleDownloadJSON}>
                    <Download /> Download json
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    onClick={handleRawJsonSubmit}
                    color="primary"
                    loading={leadEventLoading || vadiumEventLoading}
                  >
                    Submit
                  </LoadingButton>
                </Paper>
              </>
            )}
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default SimulatorDetail;
