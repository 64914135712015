import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { updateCacheTags } from '../../store/slices/cache-tag-slice';
import { portalApi } from '../portal/portalApi';
import { basicHeaders, basicRehydration } from '../utils.js';
import { alertConfigurationsApi } from './alertConfigurationsApi.js';
import { clientOwnersApi } from './clientOwnersApi.js';
import { clientsApi } from './clientsApi.js';
import { contactApi } from './contactApi.js';
import { customerGroupsApi } from './customerGroupsApi.js';
import { facilitiesApi } from './facilitiesApi.js';
import { fleetsApi } from './fleetsApi.js';
import { goalsApi } from './goalsApi.js';
import { miscApi } from './miscApi.js';
import { motivePowerApi } from './motivePowerApi.js';
import { newGoalsApi } from './newGoalsApi.js';
import { newTeamsApi } from './newTeamsApi.js';
import { notesApi } from './notesApi.js';
import { powerLoggersApi } from './powerLoggersApi.js';
import { BuilderType, projectsCacheTags } from './projectsApiHelpers.js';
import { resourcesApi } from './resourcesApi.js';
import { scheduleGroupsApi } from './scheduleGroupsApi.js';
import { schedulesApi } from './schedulesApi.js';
import { serviceProjectsApi } from './serviceProjectsApi.js';
import { structuresApi } from './structuresApi.js';
import { tagsApi } from './tagsApi.js';
import { taskDataApi } from './taskDataApi.js';
import { tasksApi } from './tasksApi.js';
import { teamsApi } from './teamApi.js';
import { thirdPartyApi } from './thirdPartyApi.js';
import { vehicleApi } from './vehicleApi.js';
import { viewsApi } from './viewsApi.js';
import { portalCacheTags } from '../portal/portalApiHelpers.js';
import { userConfigsApi } from './userConfigsApi.js';

const portalTags = Object.values(portalCacheTags);
const projectsTags = Object.values(projectsCacheTags);
export const abc = 123;

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  // prefer caching APIs on the server side
  extractRehydrationInfo: basicRehydration('projectsApi', [
    // "getClients",
    // "getFleets",
    // "getNewTeams",
    // "getAlertConfigurations",
  ]),
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_PROJECTS_SERVICE}/`,
    prepareHeaders: basicHeaders,
  }),
  tagTypes: [...projectsTags, ...portalTags],
  endpoints: (builder: BuilderType) => ({
    getCacheTags: builder.query<any, void>({
      query: () => `service/cache-tag`,
      providesTags: [projectsCacheTags.CLIENTS],
      onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
        const { data } = await queryFulfilled;
        const { cacheTags } = getState();
        const diffKeys = Object.keys(data).filter((key) => data[key] !== cacheTags?.[key]);
        if (diffKeys.length) {
          console.log('Invalidating cache tags', diffKeys);
          dispatch(projectsApi.util.invalidateTags(diffKeys));
          dispatch(portalApi.util.invalidateTags(diffKeys));
        }
        dispatch(updateCacheTags(data));
      },
    }),
    ...clientsApi(builder),
    ...alertConfigurationsApi(builder),
    ...clientOwnersApi(builder),
    ...contactApi(builder),
    ...userConfigsApi(builder),
    ...customerGroupsApi(builder),
    ...facilitiesApi(builder),
    ...fleetsApi(builder),
    ...goalsApi(builder),
    ...miscApi(builder),
    ...motivePowerApi(builder),
    ...newGoalsApi(builder),
    ...newTeamsApi(builder),
    ...notesApi(builder),
    ...powerLoggersApi(builder),
    ...serviceProjectsApi(builder),
    ...resourcesApi(builder),
    ...scheduleGroupsApi(builder),
    ...schedulesApi(builder),
    ...structuresApi(builder),
    ...tagsApi(builder),
    ...taskDataApi(builder),
    ...tasksApi(builder),
    ...teamsApi(builder),
    ...thirdPartyApi(builder),
    ...vehicleApi(builder),
    ...viewsApi(builder),
  }),
});
export const {
  useGetClientsQuery,
  useGetClientQuery,
  useGetAlertConfigurationsQuery,
  useGetNewTeamsQuery,
  useGetUserConfigsQuery,
  useLazyGetUserConfigsQuery,
} = projectsApi;
