import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Structure = {};
export const structuresApi = (builder: BuilderType) => ({
  // prettier-ignore
  updateTeamStructure: builder.mutation<Structure,{ structure: any; structureId: number }>({
    query: ({ structure, structureId }) => ({
      url: `service/structures/${structureId}`,
      method: "PUT",
      body: structure,
    }),
    invalidatesTags: [projectsCacheTags.STRUCTURES],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_TEAM_STRUCTURE), */
  }),
});
