import React from 'react';
import { Container, Typography, Grid, Paper, Button } from '@mui/material';
import PageContainer from '../components/PageContainer';

const AssetManagerPage: React.FC = () => {
  return (
    <PageContainer>
      <Container>
        <Typography variant="h4" gutterBottom>
          Asset Manager
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '16px' }}>
              <Typography variant="h6">Asset List</Typography>
              {/* Asset list content goes here */}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary">
              Add New Asset
            </Button>
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default AssetManagerPage;
