import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

interface AuthState {
  user: User;
  exp: number;
  token: string;
  email: string;
}

interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  locale: null;
  temp_unit: string;
  profile_image_url: null;
}

const slice = createSlice({
  name: "auth",
  initialState: { user: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { email, token },
      }: PayloadAction<{ email: string; token: string }>
    ) => {
      const decoded: { user: User; exp: number } = jwtDecode(token);
      state.user = decoded.user;
      state.exp = decoded.exp;
      state.email = email;
      state.token = token;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;
// export const selectCurrentUser = (state: RootState) => state.auth.user;
