import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Container,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { PageContainer, PageContainerToolbar } from '@toolpad/core';

const Sidebar = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
}));

const ProfilePage: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState('settings');
  const [avatar, setAvatar] = useState<string | ArrayBuffer | null>(null);

  const handleMenuClick = (menu: string) => {
    setSelectedMenu(menu);
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <PageContainer>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Sidebar>
            <MenuList>
              <MenuItem
                selected={selectedMenu === 'settings'}
                onClick={() => handleMenuClick('settings')}
              >
                Settings
              </MenuItem>
              <MenuItem
                selected={selectedMenu === 'password'}
                onClick={() => handleMenuClick('password')}
              >
                Password
              </MenuItem>
              <MenuItem
                selected={selectedMenu === 'notifications'}
                onClick={() => handleMenuClick('notifications')}
              >
                Notifications
              </MenuItem>
            </MenuList>
          </Sidebar>
        </Grid>
        <Grid item xs={9}>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h4">Profile Settings</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
              <Avatar
                src={typeof avatar === 'string' ? avatar : undefined}
                style={{ width: 100, height: 100, marginRight: 16 }}
              />
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="avatar-upload"
                type="file"
                onChange={handleAvatarChange}
              />
              <label htmlFor="avatar-upload">
                <Button variant="contained" component="span">
                  Upload New Photo
                </Button>
              </label>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ProfilePage;
