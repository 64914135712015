import { SvgIcon } from '@mui/material';

export interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  fill?: string;
  fillOpacity?: number;
  style?: any;
  strokeWidth?: number;
  outline?: string;
  viewBox?: string;
}

const AssetIcon = (props: IconProps) => {
  const { width, height, color } = props;

  return (
    <SvgIcon
      width={width || 'auto'}
      height={height || '1em'}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        d="M26.5815 7.46665H24.8002V5.33331H20.5335V7.46665H18.7522C17.9735 7.46665 17.3335 8.10665 17.3335 8.88531V25.2373C17.3335 26.0266 17.9735 26.6666 18.7522 26.6666H26.5708C27.3602 26.6666 28.0002 26.0266 28.0002 25.248V8.88531C28.0002 8.10665 27.3602 7.46665 26.5815 7.46665Z"
        fill={color}
      />
      <path
        d="M4 5.33331V17.0666H7.2V26.6666L14.6667 13.8666H10.4L14.6667 5.33331H4Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AssetIcon;
