import { BuilderType, portalCacheTags } from './portalApiHelpers';
export type Asset = {
  id: number;
  name: string;
  asset_type: string;
  created_at: string;
  updated_at: string;
  compartment_id: null;
  salt: string;
  alias: string;
  account_id: number;
  simulated: boolean;
};
export type AssetSearch = {
  results: Asset[];
  meta: {};
};

export const assetsApi = (builder: BuilderType) => ({
  getAssets: builder.query<any, void>({
    query: () => `service/assets.json`,
    providesTags: [portalCacheTags.ASSETS],
    /* onQueryStarted: legacyFulfill(PORTAL_RECEIVED_GET_ASSETS), */
  }),

  createAsset: builder.mutation<any, Asset>({
    query: (asset) => ({
      method: 'POST',
      url: `/service/assets.json`,
      body: asset,
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    /* onQueryStarted: legacyFulfill(PORTAL_RECEIVED_CREATE_ASSET), */
  }),

  searchAsset: builder.query<AssetSearch, Record<string, any>>({
    query: (query?: Record<string, string>) => {
      const queryString = new URLSearchParams(query ?? {}).toString();
      return `service/assets/search.json?${queryString}`;
    },
    providesTags: [portalCacheTags.ASSETS],
  }),

  // prettier-ignore
  createAssetWithConfiguration: builder.mutation<any,{ asset: Asset; configuration: any }>({
    query: ({ asset, configuration }) => ({
      method: "POST",
      url: `/service/assets/with-config.json`,
      body: { ...asset, configuration },
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    /* onQueryStarted: legacyFulfill(PORTAL_RECEIVED_CREATE_ASSET), */
  }),

  updateAsset: builder.mutation<Asset, Asset>({
    query: (asset) => ({
      method: 'PUT',
      url: `/service/assets/${asset.id}.json`,
      body: asset,
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    /* onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_ASSET), */
  }),

  updateAssetAlias: builder.mutation<Asset, Asset>({
    query: (asset) => ({
      method: 'PUT',
      url: `/service/assets/alias/${asset.id}.json`,
      body: asset,
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    /* onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_ASSET), */
  }),

  updateAssetAccount: builder.mutation<Asset, Asset>({
    query: (asset) => ({
      method: 'PUT',
      url: `/service/assets/account/${asset.id}.json`,
      body: asset,
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    /* onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_ASSET), */
  }),

  // prettier-ignore
  moveAssetList: builder.mutation<Asset,{ destinationAccountId: string; ids: string[] }>({
    query: ({ destinationAccountId, ids }) => ({
      method: "POST",
      url: "/service/assets/move-list",
      body: {
        destination_id: destinationAccountId,
        ids: ids,
      },
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    /* onQueryStarted: legacyFulfill(PORTAL_RECEIVED_MOVE_ASSET_LIST), */
  }),
});
