import { BuilderType, portalCacheTags } from "./portalApiHelpers";

export type GroupDefinition = {};

export const groupDefinitionsApi = (builder: BuilderType) => ({
  getGroupDefinitions: builder.query<GroupDefinition, void>({
    query: () => `service/group-definitions.json`,
    providesTags: [portalCacheTags.GROUP_DEFINITIONS],
    /* onQueryStarted: legacyFulfill(PORTAL_RECEIVED_GET_GROUP_DEFINITIONS), */
  }),
});
