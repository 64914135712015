import { SvgIcon, SvgIconProps } from '@mui/material';

const Binoculars = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8124 0.000123765H18.7745L18.7486 0L18.7228 0.000123765H18.687C17.2143 0.000123765 16.0205 1.19396 16.0205 2.66664V2.74454C16.0198 2.77087 16.0195 2.7973 16.0195 2.82381C16.0195 2.85033 16.0198 2.87675 16.0205 2.90309V5.02472C16.0205 5.59146 15.5611 6.05091 14.9943 6.05091C14.4276 6.05091 13.9681 5.59146 13.9681 5.02472V3.12788C13.9785 3.02803 13.9838 2.92662 13.9838 2.82394C13.9838 2.69293 13.9751 2.564 13.9584 2.43773C13.8423 1.07221 12.6972 0.000123765 11.3016 0.000123765H11.2546H11.1763C10.1542 0.000123765 9.26635 0.575221 8.81879 1.41949L8.80664 1.41223L7.23122 4.23555C7.20621 4.23485 7.18111 4.2345 7.15594 4.2345C5.9731 4.2345 4.96602 5.01306 4.58727 6.10192L1.57861 12.8728C0.793957 14.0225 0.333008 15.4256 0.333008 16.9401C0.333008 20.8387 3.38755 23.9991 7.15552 23.9991C10.7 23.9991 13.6132 21.2025 13.9463 17.6255V17.3052C13.9463 16.7246 14.417 16.2539 14.9976 16.2539C15.5782 16.2539 16.0489 16.7246 16.0489 17.3052V17.5993C16.3702 21.1888 19.2889 23.9991 22.842 23.9991C26.61 23.9991 29.6646 20.8387 29.6646 16.9401C29.6646 15.4628 29.226 14.0916 28.4763 12.9581L28.4776 12.9575L25.3262 5.86536L25.3185 5.86901C24.8847 4.90373 23.9391 4.2345 22.8425 4.2345C22.8152 4.2345 22.7881 4.23491 22.7611 4.23573L21.1855 1.41223L21.1707 1.4211C20.7234 0.575953 19.8351 0.000123765 18.8124 0.000123765ZM10.9998 17.3324C10.9998 19.5418 9.20879 21.3328 6.99942 21.3328C4.79006 21.3328 2.99902 19.5418 2.99902 17.3324C2.99902 15.1231 4.79006 13.332 6.99942 13.332C9.20879 13.332 10.9998 15.1231 10.9998 17.3324ZM22.9994 21.3328C25.2088 21.3328 26.9998 19.5418 26.9998 17.3324C26.9998 15.1231 25.2088 13.332 22.9994 13.332C20.7901 13.332 18.999 15.1231 18.999 17.3324C18.999 19.5418 20.7901 21.3328 22.9994 21.3328Z"
    />
    <rect x="13.9678" y="8" width="2.06271" height="5.33387" rx="1.03135" fill="#1B2328" />
  </SvgIcon>
);
export default Binoculars;
