import { Action } from '@reduxjs/toolkit';
import { BaseQueryApi } from '@reduxjs/toolkit/query';
import { REHYDRATE } from 'redux-persist';
import { AppState } from '../store/store';

export function isHydrateAction(action: Action): action is Action<typeof REHYDRATE> & {
  key: string;
  payload: any;
  err: unknown;
} {
  return action.type === REHYDRATE;
}

export const legacyFulfill =
  (action: string) =>
  async (_, { dispatch, queryFulfilled }) => {
    try {
      const { data } = await queryFulfilled;
      dispatch({ type: action, payload: data });
    } catch (e) {
      console.log('error', e.message);
    }
  };

export const basicHeaders = (
  headers: Headers,
  { getState }: Pick<BaseQueryApi, 'type' | 'getState' | 'extra' | 'endpoint' | 'forced'>
) => {
  const token = (getState() as AppState).auth.token;
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }
  return headers;
};

export const basicRehydration = (reducerPath: string, paths: string[]) => (action: Action) => {
  if (isHydrateAction(action) && action.payload) {
    if (!action.payload) return undefined;
    console.log('💧REHYDRATING -', reducerPath);
    const rehydrated = action.payload[reducerPath];
    if (rehydrated && paths) {
      const filteredRehydrated = {
        ...rehydrated,
        queries: {},
        subscriptions: {},
      };
      // If we have paths, we only want to rehydrate the queries and subscriptions that are in the paths array
      Object.keys(rehydrated.queries).forEach((key) => {
        if (paths.includes(rehydrated.queries[key].endpointName)) {
          filteredRehydrated.queries[key] = rehydrated.queries[key];
          if (filteredRehydrated.subscriptions[key]) {
            filteredRehydrated.subscriptions[key] = rehydrated.subscriptions[key];
          }
        }
      });
      return filteredRehydrated;
    }

    return rehydrated;
  }
};
