import { cloneDeep } from "lodash";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Fleet = {};
export type AssociationTemplate = {};
export type FleetAssociation = {};
export type AssetTemplate = {};
export type Zone = {};
export type FleetAsset = {};
export type TelemetryAssociation = {};

export const fleetsApi = (builder: BuilderType) => ({
  getFleets: builder.query<Fleet[], void>({
    query: () => `service/fleets`,
    providesTags: [projectsCacheTags.FLEETS],
  }),

  getFleet: builder.query<Fleet, number>({
    query: (id) => `service/fleets/${id}`,
    providesTags: [projectsCacheTags.FLEETS],
  }),

  createFleet: builder.mutation<Fleet, any>({
    query: (fleet) => ({
      url: `service/fleets`,
      method: "POST",
      body: fleet,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  getZones: builder.query<Zone[], number>({
    query: (fleetId) => `service/fleets/${fleetId}/zones`,
    providesTags: [projectsCacheTags.FLEETS],
  }),

  createZone: builder.mutation<Zone, { fleetId: number; zone: any }>({
    query: ({ fleetId, zone }) => ({
      url: `service/fleets/${fleetId}/zones`,
      method: "POST",
      body: zone,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  updateZone: builder.mutation<Zone, { fleetId: number; zone: any }>({
    query: ({ fleetId, zone }) => ({
      url: `service/fleets/${fleetId}/zones/${zone.id}`,
      method: "PUT",
      body: zone,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  deleteZone: builder.mutation<null, { fleetId: number; zoneId: number }>({
    query: ({ fleetId, zoneId }) => ({
      url: `service/fleets/${fleetId}/zones/${zoneId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  getAssetTemplates: builder.query<AssetTemplate, number>({
    query: (fleetId) => `service/fleets/${fleetId}/asset-templates`,
    providesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  createAssetTemplate: builder.mutation<AssetTemplate,{ fleetId: number; assetTemplate: any }>({
    query: ({ fleetId, assetTemplate }) => ({
      url: `service/fleets/${fleetId}/asset-templates`,
      method: "POST",
      body: fixupAssetTemplateData(assetTemplate),
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  updateAssetTemplate: builder.mutation<AssetTemplate,{ fleetId: number; assetTemplate: any }>({
    query: ({ fleetId, assetTemplate }) => ({
      url: `service/fleets/${fleetId}/asset-templates/${assetTemplate.id}`,
      method: "PUT",
      body: fixupAssetTemplateData(assetTemplate),
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  deleteAssetTemplate: builder.mutation<AssetTemplate,{ fleetId: number; assetTemplateId: number }>({
    query: ({ fleetId, assetTemplateId }) => ({
      url: `service/fleets/${fleetId}/asset-templates/${assetTemplateId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  getAssociationTemplates: builder.query<AssociationTemplate, number>({
    query: (fleetId) => `service/fleets/${fleetId}/association-templates`,
    providesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  createAssociationTemplate: builder.mutation<AssociationTemplate,{ fleetId: number; associationTemplate: any }>({
    query: ({ fleetId, associationTemplate }) => ({
      url: `service/fleets/${fleetId}/association-templates`,
      method: "POST",
      body: associationTemplate,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  updateAssociationTemplate: builder.mutation<AssociationTemplate,{ fleetId: number; associationTemplate: any }>({
    query: ({ fleetId, associationTemplate }) => ({
      url: `service/fleets/${fleetId}/association-templates/${associationTemplate.id}`,
      method: "PUT",
      body: associationTemplate,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  deleteAssociationTemplate: builder.mutation<AssociationTemplate,{ fleetId: number; associationTemplateId: number }>({
    query: ({ fleetId, associationTemplateId }) => ({
      url: `service/fleets/${fleetId}/association-templates/${associationTemplateId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  getAssociations: builder.query<FleetAssociation[], number>({
    query: (fleetId) => `service/fleets/${fleetId}/fleet-associations`,
    providesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  createAssociation: builder.mutation<FleetAssociation,{ fleetId: number; association: any }>({
    query: ({ fleetId, association }) => ({
      url: `service/fleets/${fleetId}/fleet-associations`,
      method: "POST",
      body: association,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  updateAssociation: builder.mutation<FleetAssociation,{ fleetId: number; association: any }>({
    query: ({ fleetId, association }) => ({
      url: `service/fleets/${fleetId}/fleet-associations/${association.id}`,
      method: "PUT",
      body: association,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  deleteAssociation: builder.mutation<null,{ fleetId: number; associationId: number }>({
    query: ({ fleetId, associationId }) => ({
      url: `service/fleets/${fleetId}/fleet-associations/${associationId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  getFleetAssets: builder.query<FleetAsset, number>({
    query: (fleetId) => `service/fleets/${fleetId}/assets`,
    providesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  createFleetAsset: builder.mutation<FleetAsset,{ fleetId: number; asset: any }>({
    query: ({ fleetId, asset }) => ({
      url: `service/fleets/${fleetId}/assets`,
      method: "POST",
      body: asset,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  updateFleetAsset: builder.mutation<FleetAsset,{ fleetId: number; asset: any }>({
    query: ({ fleetId, asset }) => ({
      url: `service/fleets/${fleetId}/assets/${asset.id}`,
      method: "PUT",
      body: asset,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  retireFleetAsset: builder.mutation<FleetAsset,{ fleetId: number; assetId: number; date: string }>({
    query: ({ fleetId, assetId, date }) => ({
      url: `service/fleets/${fleetId}/assets/${assetId}/retire`,
      method: "PUT",
      body: { retire_date: date },
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  deleteFleetAsset: builder.mutation<FleetAsset,{ fleetId: number; assetId: number }>({
    query: ({ fleetId, assetId }) => ({
      url: `service/fleets/${fleetId}/assets/${assetId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  getTelemetryAssociations: builder.query<TelemetryAssociation[], number>({
    query: (fleetId) => `service/fleets/${fleetId}/telemetry-associations`,
    providesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  createTelemetryAssociation: builder.mutation<TelemetryAssociation,{ fleetId: number; association: any }>({
    query: ({ fleetId, association }) => ({
      url: `service/fleets/${fleetId}/telemetry-associations`,
      method: "POST",
      body: association,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  updateTelemetryAssociation: builder.mutation<TelemetryAssociation,{ fleetId: number; association: any }>({
    query: ({ fleetId, association }) => ({
      url: `service/fleets/${fleetId}/telemetry-associations/${association.id}`,
      method: "PUT",
      body: association,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  endTelemetryAssociation: builder.mutation<TelemetryAssociation,{ fleetId: number; associationId: number; endDate: string }>({
    query: ({ fleetId, associationId, endDate }) => ({
      url: `service/fleets/${fleetId}/telemetry-associations/${associationId}/end-job`,
      method: "PUT",
      body: { end_date: endDate },
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  // prettier-ignore
  deleteTelemetryAssociation: builder.mutation<TelemetryAssociation,{ fleetId: number; associationId: number }>({
    query: ({ fleetId, associationId }) => ({
      url: `service/fleets/${fleetId}/telemetry-associations/${associationId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),

  uploadFleetData: builder.mutation<any, { fleetId: number; data: any }>({
    query: ({ fleetId, data }) => ({
      url: `service/fleets/${fleetId}/bulk-upload`,
      method: "POST",
      body: data,
    }),
    invalidatesTags: [projectsCacheTags.FLEETS],
  }),
});

export function fixupAssetTemplateData(template) {
  const copy = cloneDeep(template);

  copy.asset_data = JSON.stringify(template.asset_data);

  return copy;
}
