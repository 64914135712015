import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Goal = {};

export const goalsApi = (builder: BuilderType) => ({
  createGoal: builder.mutation<Goal, any>({
    query: (goal) => ({
      url: `service/goals`,
      method: "POST",
      body: goal,
    }),
    invalidatesTags: [projectsCacheTags.GOALS],
  }),

  updateGoal: builder.mutation<Goal, any>({
    query: (goal) => ({
      url: `service/goals/${goal.id}`,
      method: "PUT",
      body: goal,
    }),
    invalidatesTags: [projectsCacheTags.GOALS],
  }),

  deleteGoal: builder.mutation<Goal, number>({
    query: (id) => ({
      url: `service/goals/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.GOALS],
  }),
});
