import { Box, useColorScheme } from '@mui/material';
import { ThemeSwitch } from './ThemeSwitch';
import { useEffect } from 'react';
import { useLocalStorageState } from '@toolpad/core';

export const MainToolbarActions = () => {
  const { setMode, mode } = useColorScheme();
  const [_, setToolpadMode] = useLocalStorageState('toolpad-mode');

  useEffect(() => {
    setToolpadMode(mode!);
  }, [mode]);

  const handleChange = () => {
    setMode(mode == 'dark' ? 'light' : 'dark');
  };

  return (
    <Box alignItems={'center'} display={'flex'}>
      <ThemeSwitch onChange={handleChange} checked={mode == 'dark'} />
    </Box>
  );
};
