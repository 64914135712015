import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Battery = {};
export type Charger = {};
export type MotivePower = {};

export const motivePowerApi = (builder: BuilderType) => ({
  getMotivePower: builder.query<MotivePower, void>({
    query: () => `service/motive-power`,
    providesTags: [projectsCacheTags.MOTIVE_POWER],
  }),

  getBatteries: builder.query<Battery[], void>({
    query: () => `service/motive-power/batteries`,
    providesTags: [projectsCacheTags.MOTIVE_POWER],
  }),

  createBattery: builder.mutation<Battery, Battery>({
    query: (battery) => ({
      url: `service/motive-power/batteries`,
      method: "POST",
      body: battery,
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
  }),

  updateBattery: builder.mutation<Battery, any>({
    query: (battery) => ({
      url: `service/motive-power/batteries/${battery.id}`,
      method: "PUT",
      body: battery,
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
  }),

  deleteBattery: builder.mutation<Battery, number>({
    query: (batteryId) => ({
      url: `service/motive-power/batteries/${batteryId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
  }),

  getChargers: builder.query<Charger, void>({
    query: () => `service/motive-power/chargers`,
    providesTags: [projectsCacheTags.MOTIVE_POWER],
  }),

  createCharger: builder.mutation<Charger, Partial<Charger>>({
    query: (charger) => ({
      url: `service/motive-power/chargers`,
      method: "POST",
      body: charger,
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
  }),

  updateCharger: builder.mutation<Charger, Partial<Charger>>({
    query: (charger) => ({
      url: `service/motive-power/chargers/${charger.id}`,
      method: "PUT",
      body: charger,
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
  }),

  deleteCharger: builder.mutation<null, number>({
    query: (chargerId) => ({
      url: `service/motive-power/chargers/${chargerId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
  }),
});
