import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type ClientOwner = {};

export const clientOwnersApi = (builder: BuilderType) => ({
  getClientOwners: builder.query<ClientOwner, void>({
    query: () => `service/client-owners`,
    providesTags: [projectsCacheTags.CLIENT_OWNERS],
  }),

  createClientOwner: builder.mutation<ClientOwner, any>({
    query: (owner) => ({
      url: `service/client-owners`,
      method: "POST",
      body: owner,
    }),
    invalidatesTags: [projectsCacheTags.CLIENT_OWNERS],
  }),

  updateClientOwner: builder.mutation<ClientOwner, any>({
    query: (owner) => ({
      url: `service/client-owners/${owner.id}`,
      method: "PUT",
      body: owner,
    }),
    invalidatesTags: [projectsCacheTags.CLIENT_OWNERS],
  }),

  deleteClientOwner: builder.mutation<ClientOwner, number>({
    query: (id) => ({
      url: `service/client-owners/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.CLIENT_OWNERS],
  }),
});
