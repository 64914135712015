import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

const dealers = [
  {
    id: 1,
    name: "Dealer One",
    description: "Description for Dealer One",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    name: "Dealer Two",
    description: "Description for Dealer Two",
    image: "https://via.placeholder.com/150",
  },
  // Add more dealers as needed
];

const DealersPage: React.FC = () => {
  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Dealers
      </Typography>
      <Grid container spacing={4}>
        {dealers.map((dealer) => (
          <Grid item key={dealer.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={dealer.image}
                alt={dealer.name}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {dealer.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {dealer.description}
                </Typography>
              </CardContent>
              <Button size="small">Learn More</Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DealersPage;
