import { Box, Tab, Tabs } from '@mui/material';
import { FieldProps, RJSFSchema } from '@rjsf/utils';
import { useMemo, useState } from 'react';
import { Form } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import JSONSchemaArrayTabs from './JSONSchemaArrayTabs';

const JSONSchemaMainTabs = ({ schema, formData, onChange, uiSchema, ...other }: FieldProps) => {
  const { tabs, simple, partial, fields } = useMemo(() => {
    const _partial: RJSFSchema = {};
    const _simple: RJSFSchema = { type: 'object', properties: {} };
    const _tabs: { id: string; title: string }[] = [];
    const _fields = {};
    Object.entries(schema.properties).forEach(([key, value]) => {
      if (value.type === 'object' || value.type === 'array') {
        _partial[key] = value;
        _tabs.push({ id: key, title: key });
        if (value.$id?.includes('mainTabs')) {
          _fields[value.$id] = JSONSchemaMainTabs;
        }
        if (value.$id?.includes('arrayTabs')) {
          _fields[value.$id] = JSONSchemaArrayTabs;
        }
      } else {
        _simple.properties![key] = value;
      }
    });

    return { tabs: _tabs, simple: _simple, partial: _partial, fields: _fields };
  }, [schema]);

  console.log();
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);

  const handleSimpleChange = (data: any) => {
    onChange(data.formData);
  };
  const handleFormChange = (data: any) => {
    onChange({ ...formData, [selectedTab]: data.formData });
  };
  const handleSubmit = (data: any) => {
    console.log(data);
  };
  return (
    <>
      <Form
        schema={simple}
        formData={formData}
        experimental_defaultFormStateBehavior={{
          emptyObjectFields: 'populateRequiredDefaults',
        }}
        uiSchema={uiSchema}
        validator={validator}
        onChange={handleSimpleChange}
        onSubmit={handleSubmit}
      >
        <div></div>
      </Form>
      <Tabs value={selectedTab}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            label={tab.title}
            value={tab.id}
            onClick={() => setSelectedTab(tab.id)}
          />
        ))}
      </Tabs>
      <Box padding={2}>
        <Form
          schema={partial[selectedTab]}
          formData={formData[selectedTab]}
          experimental_defaultFormStateBehavior={{
            emptyObjectFields: 'populateRequiredDefaults',
          }}
          fields={fields}
          validator={validator}
          onChange={handleFormChange}
          onSubmit={handleSubmit}
        >
          <div></div>
        </Form>
      </Box>
    </>
  );
};

export default JSONSchemaMainTabs;
