import { BuilderType } from "./projectsApiHelpers";

interface UserConfig {
  stringTable: StringTable;
  appConfig: AppConfig;
  softwareVersion: string;
}

interface AppConfig {
  mainTitleName: string;
  menu: Menu[];
  routes: Route[];
  fallbackRoute: FallbackRoute;
}

interface FallbackRoute {
  route: string;
  index: string;
}

interface Route {
  route: string;
  index: string;
  componentName: string;
  params: string[];
}

interface Menu {
  nav: string;
  type: string;
  navId?: string;
  labelName?: string;
  iconName?: string;
  children?: any[];
}

interface StringTable {
  [lang: string]: { [key: string]: string };
}

export const userConfigsApi = (builder: BuilderType) => ({
  getUserConfigs: builder.query<UserConfig[], void>({
    query: () => ({
      url: `service/user-configs`,
      method: "GET",
    }),
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_USER_CONFIG), */
  }),
});
