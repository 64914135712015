import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { basicHeaders } from './utils';

export const connectApi = createApi({
  reducerPath: 'connectApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_CONNECT_SERVICE}/`,
    prepareHeaders: basicHeaders,
  }),
  endpoints: (builder) => ({
    vanadiumEvent: builder.mutation<any, any>({
      query: (body) => ({
        url: `vanadium-bess`,
        method: 'POST',
        body,
      }),
    }),
    leadEvent: builder.mutation<any, any>({
      query: (body) => ({
        url: `leadacid-bess`,
        method: 'POST',
        body,
      }),
    }),
    lastLeadEventBySerial: builder.query<any, string>({
      query: (serial) => `leadacid-bess/${serial}`,
    }),
    lastVanadiumEventBySerial: builder.query<any, string>({
      query: (serial) => `vanadium-bess/${serial}`,
    }),
  }),
});
export const {
  useVanadiumEventMutation,
  useLeadEventMutation,
  useLastLeadEventBySerialQuery,
  useLastVanadiumEventBySerialQuery,
} = connectApi;
