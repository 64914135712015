import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';

import { useTheme } from '@mui/material';
import { Asset } from '../../services/portal/assetsApi';
import { useSearchAssetQuery } from '../../services/portal/portalApi';

interface ColumnData {
  dataKey: keyof Asset;
  label: string;
  numeric?: boolean;
  width?: number;
}

const columns: ColumnData[] = [
  {
    width: 100,
    label: 'Name',
    dataKey: 'name',
  },
  {
    width: 100,
    label: 'Alias',
    dataKey: 'alias',
  },
  {
    width: 100,
    label: 'Asset type',
    dataKey: 'asset_type',
  },
  {
    width: 100,
    label: 'Created at',
    dataKey: 'created_at',
  },
  {
    width: 100,
    label: 'Updated at',
    dataKey: 'updated_at',
  },
];

/* const rows: Data[] = Array.from({ length: 200 }, (_, index) => createData(index)); */

const VirtuosoTableComponents: TableComponents<Asset> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{ backgroundColor: 'background.paper' }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function SimulatorTable({ onRowClick }: { onRowClick: (row: Asset) => void }) {
  const { data } = useSearchAssetQuery({ simulated: true, sort: 'name|asc' });

  const theme = useTheme();

  const handleRowClick = React.useCallback((row: Asset) => {
    console.log(row);
  }, []);

  const rowContent = React.useCallback(
    (_index: number, row: Asset) => {
      return (
        <Paper
          elevation={0}
          sx={{
            display: 'contents',
            '&:hover .MuiTableCell-root': {
              background: theme.palette.background.paper,
              cursor: 'pointer',
            },
          }}
          onClick={() => onRowClick(row)}
        >
          {columns.map((column) => (
            <TableCell key={column.dataKey} align={column.numeric || false ? 'right' : 'left'}>
              {row[column.dataKey]}
            </TableCell>
          ))}
        </Paper>
      );
    },
    [theme, handleRowClick]
  );

  return (
    <>
      <Paper style={{ height: '100vh', width: '100%' }}>
        <TableVirtuoso
          data={data?.results ?? []}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Paper>
    </>
  );
}
