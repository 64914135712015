import { AuthenticationContext, SessionContext } from '@toolpad/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import { Account as AccountToolpad } from '@toolpad/core';
import AccountMenu from './AccountMenu';

const Account: React.FC = () => {
  const auth = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();

  const name = auth.user?.first_name || 'User' + ' ' + auth.user?.last_name || '';
  const user = { ...structuredClone(auth.user), name };
  const session = { user };
  const authentication = React.useMemo(() => {
    return {
      /* signIn: () => {
        setSession(demoSession);
      }, */
      signOut: () => {
        dispatch({ type: 'LOGOUT_USER' });
      },
    };
  }, [dispatch]);

  return (
    <AuthenticationContext.Provider value={authentication}>
      <SessionContext.Provider value={session}>
        <AccountToolpad
          slots={{
            popoverContent: AccountMenu,
          }}
        />
      </SessionContext.Provider>
    </AuthenticationContext.Provider>
  );
};

export default Account;
