import { Home } from '@mui/icons-material';
import { Box, Breadcrumbs, IconButton, Link, Typography } from '@mui/material';
import { useLocation, Link as RouterLink, useNavigate } from 'react-router-dom';
import { navItems } from '../sideNavigation';

type PageContainerProps = {
  actions?: React.ReactNode;
  children: React.ReactNode;
};
const PageContainer = ({ actions, children }: PageContainerProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const paths = pathname.split('/').filter((path) => path !== '');

  const routes = paths.map((path, index) => ({
    path,
    route: `/${paths.slice(0, index + 1).join('/')}`,
  }));
  const icon = navItems.find((item) => item.segment === paths[paths.length - 1])?.icon;

  const handleHomeClick = () => {
    navigate('/');
  };
  return (
    <Box sx={{ padding: '35px 32px' }}>
      <Breadcrumbs separator="›">
        <IconButton sx={{ ml: '-6px' }} onClick={handleHomeClick}>
          <Home />
        </IconButton>
        {routes.map(({ path, route }, index) => (
          <Link
            underline="hover"
            color={index === routes.length - 1 ? 'primary' : 'text.secondary'}
            fontWeight={index === routes.length - 1 ? 'bold' : 'normal'}
            component={RouterLink}
            to={route}
            textTransform={'capitalize'}
          >
            {path}
          </Link>
        ))}
      </Breadcrumbs>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <Typography
          variant="h2"
          color="textSecondary"
          sx={{ textAlign: 'left', display: 'flex', textTransform: 'capitalize', mb: '24px' }}
        >
          <Box
            sx={{
              svg: {
                height: '24px',
                width: 'auto',
                marginRight: '20px',
              },
            }}
          >
            {icon}
          </Box>
          {paths[paths.length - 1]}
        </Typography>
        {actions}
      </Box>
      {children}
    </Box>
  );
};

export default PageContainer;
