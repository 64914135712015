import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type CustomerGroup = {};
export type CustomerGroupMember = {};
export const customerGroupsApi = (builder: BuilderType) => ({
  getCustomerGroups: builder.query<CustomerGroup, void>({
    query: () => `service/customer-groups`,
    providesTags: [projectsCacheTags.CUSTOMER_GROUPS],
  }),

  createCustomerGroup: builder.mutation<CustomerGroup, any>({
    query: (customerGroup) => ({
      url: `service/customer-groups`,
      method: "POST",
      body: customerGroup,
    }),
    invalidatesTags: [projectsCacheTags.CUSTOMER_GROUPS],
  }),

  updateCustomerGroup: builder.mutation<CustomerGroup, any>({
    query: (customerGroup) => ({
      url: `service/customer-groups/${customerGroup.id}`,
      method: "PUT",
      body: customerGroup,
    }),
    invalidatesTags: [projectsCacheTags.CUSTOMER_GROUPS],
  }),

  deleteCustomerGroup: builder.mutation<CustomerGroup, number>({
    query: (id) => ({
      url: `service/customer-groups/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.CUSTOMER_GROUPS],
  }),

  createCustomerGroupMember: builder.mutation<CustomerGroupMember, any>({
    query: (customerGroupMember) => ({
      url: `service/customer-groups/members`,
      method: "POST",
      body: customerGroupMember,
    }),
    invalidatesTags: [projectsCacheTags.CUSTOMER_GROUPS],
  }),

  deleteCustomerGroupMember: builder.mutation<CustomerGroupMember, number>({
    query: (id) => ({
      url: `service/customer-groups/members/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.CUSTOMER_GROUPS],
  }),
});
