import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type AlertConfiguration = {};

export const alertConfigurationsApi = (builder: BuilderType) => ({
  getAlertConfigurations: builder.query<AlertConfiguration, void>({
    query: () => `service/alert-configurations`,
    providesTags: [projectsCacheTags.ALERT_CONFIGURATIONS],
  }),

  createAlertConfiguration: builder.mutation<AlertConfiguration, any>({
    query: (config) => ({
      url: `service/alert-configurations`,
      method: "POST",
      body: config,
    }),
    invalidatesTags: [projectsCacheTags.ALERT_CONFIGURATIONS],
  }),

  updateAlertConfiguration: builder.mutation<AlertConfiguration, any>({
    query: (config) => ({
      url: `service/alert-configurations/${config.alert_configuration.id}`,
      method: "PUT",
      body: config,
    }),
    invalidatesTags: [projectsCacheTags.ALERT_CONFIGURATIONS],
  }),
});
