import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { basicHeaders, basicRehydration } from '../utils';
import { assetsApi } from './assetsApi';
import { groupDefinitionsApi } from './groupDefinitionsApi';
import { portalCacheTags } from './portalApiHelpers';
import { roleDefinitionsApi } from './roleDefinitionsApi';
import { thirdPartyUsersApi } from './thirdPartyUsersApi';
import { userProfileApi } from './userProfileApi';
import { usersApi } from './usersApi';

export const portalApi = createApi({
  reducerPath: 'portalApi',
  extractRehydrationInfo: basicRehydration('portalApi', [
    'getAssets',
    'getGroupDefinitions',
    'getUsers',
  ]),
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_PORTAL_SERVICE}/`,
    prepareHeaders: basicHeaders,
  }),
  tagTypes: Object.values(portalCacheTags),
  endpoints: (builder) => ({
    ...assetsApi(builder),
    ...groupDefinitionsApi(builder),
    ...roleDefinitionsApi(builder),
    ...thirdPartyUsersApi(builder),
    ...userProfileApi(builder),
    ...usersApi(builder),
  }),
});

export const {
  useGetAssetsQuery,
  useLazyGetAssetsQuery,
  useGetGroupDefinitionsQuery,
  useSearchAssetQuery,
  useLazySearchAssetQuery,
} = portalApi;
