import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2,
  Paper,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import PageContainer from '../components/PageContainer';

const StyleGuidePage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [alignment, setAlignment] = useState<string | null>('left');
  const [dialogSize, setDialogSize] = useState();

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
  };
  return (
    <PageContainer>
      <Grid2 container spacing={3} textAlign={'left'}>
        <Grid2 size={{ sm: 12, md: 4 }}>
          <Paper sx={{ width: '100%' }}>
            <Box p={3}>
              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  Buttons
                </Typography>
                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                  Contained
                </Button>
                <Button variant="outlined" color="primary" sx={{ mr: 2 }}>
                  Outlined
                </Button>
                <Button variant="text" color="primary">
                  Text
                </Button>
                <Box mt={4}>
                  <Button variant="contained" color="primary" size="small" sx={{ mr: 2 }}>
                    Small
                  </Button>
                  <Button variant="contained" color="primary" size="medium" sx={{ mr: 2 }}>
                    Medium
                  </Button>
                  <Button variant="contained" color="primary" size="large">
                    Large
                  </Button>
                </Box>
                <Box mt={4}>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="left" aria-label="left aligned" color="primary">
                      Left
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered" color="primary">
                      Center
                    </ToggleButton>
                    <ToggleButton value="right" aria-label="right aligned" color="primary">
                      Right
                    </ToggleButton>
                    <ToggleButton value="justify" aria-label="justified" disabled color="primary">
                      Justify
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Box>
              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  Tabs
                </Typography>
                <Tabs value={0} sx={{ mb: '16px' }}>
                  <Tab label="Tab One" />
                  <Tab label="Tab Two" />
                  <Tab label="Tab Three" />
                </Tabs>
                <Tabs value={0} variant="scrollable" scrollButtons="auto" sx={{ mb: '16px' }}>
                  <Tab label="Scrollable Tab One" />
                  <Tab label="Scrollable Tab Two" />
                  <Tab label="Scrollable Tab Three" />
                  <Tab label="Scrollable Tab Four" />
                  <Tab label="Scrollable Tab Five" />
                  <Tab label="Scrollable Tab Six" />
                  <Tab label="Scrollable Tab Seven" />
                </Tabs>
              </Box>
              <Box mt={4}></Box>
              <Typography variant="h6" gutterBottom>
                Dialogs
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setDialogSize('xs')}
                sx={{ mr: 2 }}
              >
                Extra small
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setDialogSize('sm')}
                sx={{ mr: 2 }}
              >
                small
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setDialogSize('md')}
                sx={{ mr: 2 }}
              >
                Medium
              </Button>
              <Button variant="outlined" size="small" onClick={() => setDialogSize('lg')}>
                Large
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setDialogSize('xl')}
                sx={{ ml: 2 }}
              >
                Extra large
              </Button>
              <Dialog
                open={!!dialogSize}
                onClose={() => setOpen(false)}
                maxWidth={dialogSize}
                fullWidth
              >
                <DialogTitle>{dialogSize} Dialog</DialogTitle>
                <DialogContent>
                  <DialogContentText>This is a {dialogSize} dialog content.</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDialogSize(null)}>Close</Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Paper>
        </Grid2>
        <Grid2 size={{ sm: 12, md: 8 }}>
          <Paper sx={{ minHeight: 'calc(100vh - 230px)' }}>
            <Box p={3}>
              <Box mt={4}>
                <Typography variant="h1" gutterBottom>
                  h1. Heading
                </Typography>
                <Typography variant="h2" gutterBottom>
                  h2. Heading
                </Typography>
                <Typography variant="h3" gutterBottom>
                  h3. Heading
                </Typography>
                <Typography variant="h4" gutterBottom>
                  h4. Heading
                </Typography>
                <Typography variant="h5" gutterBottom>
                  h5. Heading
                </Typography>
                <Typography variant="h6" gutterBottom>
                  h6. Heading
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                  blanditiis tenetur
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                  blanditiis tenetur
                </Typography>
                <Typography variant="body1" gutterBottom>
                  body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis
                  tenetur
                </Typography>
                <Typography variant="body2" gutterBottom>
                  body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis
                  tenetur
                </Typography>
                <Typography variant="button" display="block" gutterBottom>
                  button text
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  caption text
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                  overline text
                </Typography>
              </Box>
              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  Colors
                </Typography>
                <Box display="flex" flexWrap="wrap">
                  {['primary', 'secondary', 'error', 'warning', 'info', 'success'].map((color) => (
                    <Box
                      key={color}
                      sx={{
                        backgroundColor: `${color}.main`,
                        color: `${color}.contrastText`,
                        width: 100,
                        height: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        m: 1,
                      }}
                    >
                      {color}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  Colors
                </Typography>
                <Box display="flex" flexWrap="wrap">
                  {['surface1', 'surface2', 'surface3', 'surface4'].map((color) => (
                    <Box
                      key={color}
                      sx={{
                        backgroundColor: `background.${color}`,
                        width: 100,
                        height: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        m: 1,
                      }}
                    >
                      {color}
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  Outlined TextField States
                </Typography>
                <TextField label="Normal" variant="filled" sx={{ mr: 2 }} />
                <TextField label="Focused" variant="filled" focused sx={{ mr: 2 }} />
                <TextField label="Disabled" variant="filled" disabled sx={{ mr: 2 }} />
                <TextField
                  label="Error"
                  variant="filled"
                  error
                  helperText="Incorrect entry."
                  sx={{ mr: 2 }}
                />
                <TextField
                  label="With Helper Text"
                  variant="filled"
                  helperText="Some important text."
                />
              </Box>

              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  Tooltips
                </Typography>
                <Grid2 container spacing={2}>
                  {[
                    'top-start',
                    'top',
                    'top-end',
                    'left-start',
                    'left',
                    'left-end',
                    'right-start',
                    'right',
                    'right-end',
                    'bottom-start',
                    'bottom',
                    'bottom-end',
                  ].map((position) => (
                    <Grid2 size={4} key={position}>
                      <Tooltip title={`Tooltip on ${position}`} placement={position}>
                        <Button color="primary">{position}</Button>
                      </Tooltip>
                    </Grid2>
                  ))}
                </Grid2>
              </Box>
            </Box>
          </Paper>
        </Grid2>
      </Grid2>
    </PageContainer>
  );
};

export default StyleGuidePage;
