import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Team = {};

export const teamsApi = (builder: BuilderType) => ({
  getTeams: builder.query<Team, void>({
    query: () => `service/teams`,
    providesTags: [projectsCacheTags.TEAMS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_TEAMS), */
  }),

  createTeam: builder.mutation<Team, Partial<Team>>({
    query: (team) => ({
      url: `service/teams`,
      method: "POST",
      body: team,
    }),
    invalidatesTags: [projectsCacheTags.TEAMS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_TEAM), */
  }),

  updateTeam: builder.mutation<Team, Partial<Team>>({
    query: (team) => ({
      url: `service/teams/${team.id}`,
      method: "PUT",
      body: team,
    }),
    invalidatesTags: [projectsCacheTags.TEAMS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_TEAM), */
  }),

  deleteTeam: builder.mutation<Team, number>({
    query: (teamId) => ({
      url: `service/teams/${teamId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.TEAMS],
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_TEAM), */
  }),
});
