import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

type Contact = {};

export const contactApi = (builder: BuilderType) => ({
  createContact: builder.mutation<Contact, Partial<Contact>>({
    query: (body) => ({
      url: `service/contacts`,
      method: "POST",
      body,
    }),
    providesTags: [projectsCacheTags.CONTACTS],
  }),

  createFacilityContact: builder.mutation<Contact, Partial<Contact>>({
    query: (body) => ({
      url: `service/contacts`,
      method: "POST",
      body,
    }),
    invalidatesTags: [projectsCacheTags.CONTACTS],
  }),

  createWorkflowContact: builder.mutation<Contact, Partial<Contact>>({
    query: (body) => ({
      url: `service/contacts`,
      method: "POST",
      body,
    }),
    invalidatesTags: [projectsCacheTags.CONTACTS],
  }),

  updateContact: builder.mutation<Contact, Partial<Contact>>({
    query: (body) => ({
      url: `service/contacts/${body.id}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: [projectsCacheTags.CONTACTS],
  }),

  deleteContact: builder.mutation<Contact, number>({
    query: (id) => ({
      url: `service/contacts/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.CONTACTS],
  }),
});
