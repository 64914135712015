import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Sample Data',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Sample Chart',
    },
  },
};

const MeasureModelPage: React.FC = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Measure and Model Page
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6" gutterBottom>
              Measure Section
            </Typography>
            <Typography variant="body1">
              This section can be used to display measurement data.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6" gutterBottom>
              Model Section
            </Typography>
            <Typography variant="body1">This section can be used to display model data.</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6" gutterBottom>
              Sample Chart
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MeasureModelPage;
