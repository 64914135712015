import { BuilderType } from "./projectsApiHelpers";

export type ThirdPartyUser = {};
export const thirdPartyApi = (builder: BuilderType) => ({
  createThirdPartyUser: builder.mutation<ThirdPartyUser, any>({
    query: (user) => ({
      url: `service/third-party-users`,
      method: "POST",
      body: user,
    }),
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_THIRD_PARTY_USER), */
  }),

  updateThirdPartyUser: builder.mutation<ThirdPartyUser, any>({
    query: (user) => ({
      url: `service/third-party-users/${user.id}`,
      method: "PUT",
      body: user,
    }),
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_THIRD_PARTY_USER), */
  }),

  deleteThirdPartyUser: builder.mutation<ThirdPartyUser, number>({
    query: (id) => ({
      url: `service/third-party-users/${id}`,
      method: "DELETE",
    }),
    /* onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_THIRD_PARTY_USER), */
  }),
});
