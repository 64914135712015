import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type NewGoal = {};

export const newGoalsApi = (builder: BuilderType) => ({
  createNewGoal: builder.mutation<NewGoal, any>({
    query: (goal) => ({
      url: `service/new-goals`,
      method: "POST",
      body: goal,
    }),
    invalidatesTags: [projectsCacheTags.NEW_GOALS],
  }),

  updateNewGoal: builder.mutation<NewGoal, any>({
    query: (goal) => ({
      url: `service/new-goals/${goal.id}`,
      method: "PUT",
      body: goal,
    }),
    invalidatesTags: [projectsCacheTags.NEW_GOALS],
  }),

  deleteNewGoal: builder.mutation<NewGoal, number>({
    query: (id) => ({
      url: `service/new-goals/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.NEW_GOALS],
  }),
});
