import { BuilderType, portalCacheTags } from "./portalApiHelpers";

export type Profile = {};

export const userProfileApi = (builder: BuilderType) => ({
  // prettier-ignore
  updateUserProfileImage: builder.mutation<Profile,{ id: string; image: string }>({
    query: ({ id, image }) => ({
      method: "POST",
      url: `/service/user-profile-images/${id}`,
      body: { profile_image: image },
    }),
    invalidatesTags: [portalCacheTags.USER_PROFILE],
  }),
});
