import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Resource = {};
export const resourcesApi = (builder: BuilderType) => ({
  updateResource: builder.mutation<any, any>({
    query: (resource) => ({
      url: `service/resources/${resource.id}`,
      method: "PUT",
      body: resource,
    }),
    invalidatesTags: [projectsCacheTags.RESOURCES],
  }),
});
