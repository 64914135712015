import {
  Box,
  Container,
  Grid2,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import PageContainer from '../components/PageContainer';

const projectData = [
  {
    projectName: 'Project Alpha',
    client: 'Client A',
    facility: 'Facility 1',
    titlePattern: 'Pattern A',
    owner: 'Owner 1',
    projectManager: 'Manager 1',
    dateCreated: '2023-01-01',
    lastActivity: '2023-01-10',
    currentAlerts: 2,
  },
  // Add more project data as needed
];

const Monitor: React.FC = () => {
  return (
    <PageContainer>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6, lg: 8, xl: 10 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell>Facility</TableCell>
                  <TableCell>Title Pattern</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Project Manager</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell>Last Activity</TableCell>
                  <TableCell>Current Alerts</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectData.map((project, index) => (
                  <TableRow key={index}>
                    <TableCell>{project.projectName}</TableCell>
                    <TableCell>{project.client}</TableCell>
                    <TableCell>{project.facility}</TableCell>
                    <TableCell>{project.titlePattern}</TableCell>
                    <TableCell>{project.owner}</TableCell>
                    <TableCell>{project.projectManager}</TableCell>
                    <TableCell>{project.dateCreated}</TableCell>
                    <TableCell>{project.lastActivity}</TableCell>
                    <TableCell>{project.currentAlerts}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 2 }}>
          <Paper>
            <Container>
              <Box sx={{ mb: 4, textAlign: 'left' }}>
                <Typography variant="h6">Summary</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box>
                    <Typography variant="body1">Total Projects: 100</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Total Batteries: 200</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Active Projects: 60</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Critical Alerts: 5</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Inactive Projects: 40</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">Top Clients: Client A, Client B</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h6">Battery Usage Trends</Typography>

                <Box></Box>
                {/* Battery Usage Trends Chart Component */}
              </Box>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h6">Projects by Region</Typography>

                <Box>
                  {/* <Pie data={projectsByRegionData} options={projectsByRegionOptions} /> */}
                </Box>
                {/* Projects by Region Chart Component */}
              </Box>
            </Container>
          </Paper>
        </Grid2>
      </Grid2>
    </PageContainer>
  );
};

export default Monitor;
